const state: () => Store.RootState = () => ({
    region: null,
    flash: null,
    showMenu: false,
    showLoginForm: false,
    loginFormParams: null,
    user: null,
    favoriteCount: 0,
    siteBroken: false,
    pageNotFound: false,
    history: [],
    recentSearch: [],
    remoteAddress: null,
    token: null,
    shouldCleanBreadcrumbs: false,
    shouldKeepScroll: false,
    breadcrumbs: [],
    imageFormat: 'jpeg',
});

export default state;
