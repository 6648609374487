
import { Component, Vue} from 'nuxt-property-decorator';
import {mapState} from 'vuex';

@Component({
    components: {
        appHeader: require('~/components/header/').default,
        appFooter: require('~/components/footer/').default,
    },
    computed: mapState(['region', 'showMenu']),
})
export default class MainComponent extends Vue {

    private showMenu: boolean;
    private region: Store.CurrentRegion;

    public head() {
        return {
            bodyAttrs: {
                class: this.showMenu ? 'no-scroll' : null,
            },
            link: [
                {
                    rel: "alternate",
                    media: "only screen and (max-width: 640px)",
                    href: "https://m.triya.ru" + this.$route.fullPath
                },
            ]
        }
    }

    get currencyClass()
    {
        if (!this.region) {
            return null;
        }

        return `currency-${this.region.country.currency}`;
    }
}