/**
 * email validation RFC 2822
 * @param email
 */
export function validateEmail(email: string): boolean {
    // tslint:disable-next-line:max-line-length
    return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(email);
}

/**
 * phone validation by me
 * @param phone
 */
export function validatePhone(phone: string): boolean {
    return /\+\d+ \(\d{3}\) \d{3}-\d{2}-\d{2}/.test(phone);
}

export function validatePassword(password: string): boolean {
    return password.length >= 6;
}
