export default function (to, from, savedPosition) {
    if (window.$nuxt.$store.state.shouldKeepScroll) {
        window.$nuxt.$store.state.shouldKeepScroll = false;
        return { x: 0, y: window.scrollY }
    }

    if (to.name === 'catalog-path' && from.name === 'catalog-path' && to.path === from.path) {
        return { x: 0, y: window.scrollY }
    }

    // console.log(to, from)

    return { x: 0, y: 0 }
}
