

import { Component, Vue, State as state} from 'nuxt-property-decorator';

@Component
export default class BreadcrumbsComponent extends Vue {

    @state breadcrumbs: Store.BreadCrumb[];
    @state history: string[];

    popHistory() {
        if (this.history.length > 1) {
            const prev = this.history[this.history.length - 2];
            this.history.pop();
            history.back();
        }  else {
            this.$router.push(this.prevLink);
        }
    }

    get prevLink() {

        if (this.history.length > 1) {
            return this.history[this.history.length - 2];
        } 

        if (this.breadcrumbs?.length < 2) {
            return '/';
        }

        for (let i = this.breadcrumbs.length - 2; i >= 0; i--) {
            if (this.breadcrumbs[i].link) {
                return this.breadcrumbs[i].link;
            }
        }

        return '/';
    }
}

