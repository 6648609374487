
import { Component, Vue} from 'nuxt-property-decorator';
import { mapState } from 'vuex';

@Component({
    components: {
        popup: require('~/components/ui/dialog').default,
        citySelector: require("./city-selector").default,
    },
    computed: mapState(['region', 'user'])
})
export default class BlackHeaderComponent extends Vue {

    showCitySelector = false;
    region!: any;

    showLoginForm() {
        this.$store.commit('setLoginFormState', true);
    }

    get phoneNumber() {
        if (!this.region?.phones?.length) {
            return null;
        }

        return this.region.phones[0].trim();
    }
}

