import { Component, Vue, Prop as prop} from 'nuxt-property-decorator';

@Component
export default class ShadedContainerComponent extends Vue {

    public static shadowClasses: (string | null)[] = [null, 'top', 'bottom', 'top bottom'];

    public shadowClass: string | null = null;
    public mutationObserver: MutationObserver | null = null;

    @prop()
    public dynamic: boolean;

    public mounted() {
        // почему-то в дефайнах вариант биндинга событий с опциями у window
        // не предусмотрен, хотя window наследуется от EventTarget и должен содержать его
        if (typeof window !== 'undefined') {
            (window as EventTarget).addEventListener('resize', this.mathScroll, {
                passive: true,
            });
        }

        (this.$refs.watchContainer as EventTarget).addEventListener('scroll', this.mathScroll, {
            passive: true,
        });

        if (this.dynamic && !this.mutationObserver) {
            this.mutationObserver = new MutationObserver(this.mathScroll);
            this.mutationObserver.observe((this.$refs.watchContainer as Node), {
                attributes: true,
                childList: true,
                characterData: true,
                subtree: true,
            });
        }

        this.mathScroll();
    }

    public beforeDestroy() {
        if (this.mutationObserver) {
            this.mutationObserver.disconnect();
            this.mutationObserver = null;
        }

        (this.$refs.watchContainer as EventTarget).removeEventListener('scroll', this.mathScroll);

        if (typeof window !== 'undefined') {
            (window as EventTarget).removeEventListener('resize', this.mathScroll);
        }
    }

    public mathScroll() {

        let el = (this.$refs.watchContainer as HTMLElement),
            scrollSize: number = el.scrollHeight - el.clientHeight,
            shadowFlag: number = 0;

        if (el.scrollTop > 0) {
            shadowFlag |= 1;
        }

        if (scrollSize > 0 && el.scrollTop < scrollSize) {
            shadowFlag |= 2;
        }

        if (this.shadowClass !== ShadedContainerComponent.shadowClasses[shadowFlag]) {
            this.shadowClass = ShadedContainerComponent.shadowClasses[shadowFlag];
        }
    }
}
