import { Component, Vue} from 'nuxt-property-decorator';
import { mapState } from 'vuex';

@Component({
    computed: mapState('cart', ['cart']),
})
export default class CartComponent extends Vue {

    public cart: CartItem[];
}
