import { IFlashAction } from "~/types/flash";
import { showFlash } from "./flash";

interface ErrorEntity {
    message: string;
    timeout?: number;
    type?: string;
    stop?: boolean;
}

const errorMessages = {
    user: {
        "not-authorized": { message: "Не авторизован", stop: true, actions: [
            {
                text: 'Войти',
                link: '/login/',
                dismiss: true,
            }
        ] },
        "already-authorized": { message: "Уже авторизован", stop: true},
        "already-exists": { message: "Такой пользователь уже зарегистрирован", stop: true, actions: [
            {
                text: 'Войти',
                link: '/login/',
                dismiss: true,
            }
        ]},
        "user-already-exists": { message: "Такой пользователь уже зарегистрирован", stop: true, actions: [
            {
                text: 'Войти',
                link: '/login/',
                dismiss: true,
            }
        ]},
        "user-blocked": { message: "Пользователь заблокирован", stop: true },
        "invalid-credentials": { message: "Неверный логин и/или пароль", stop: true },
        "wrong-password": { message: "Неверный пароль", stop: true },
        undefined: { message: "Неизвестная ошибка" }
    },
    cart: {
        "empty": { message: "Корзина пуста", stop: true },
        "item-not-found": { message: "Такой товар не найден", stop: true },
        "coupon-not-found": { message: "Купон не найден", stop: true },
        "unavailable-pay-type": { message: "Выбранный тип оплаты не доступен", stop: true },
        "unavailable-item": { message: "Товар не доступен к покупке", stop: true }
    },
    order: {
        'not-found': { message: "Заказ не найден", stop: false },
    },
    payment: {
        'already-payed': { message: "Заказ уже оплачен", stop: true },
    },
    auth: {
        'no-such-user': { message: "Такого пользователя не существует", stop: true },
        'attempt-limit-exceeded': { message: "Количество попыток истекло", stop: true },
        'invalid-token': { message: "Срок действия ссылки истёк", stop: true },
    }
};

export function handlePublicError(
    error: ErrorResponse | ErrorTextResponse,
    store
): boolean {
    if (error.type === "code") {
        const flash = getCodeError(error);

        if (!flash.type) {
            flash.type = "error";
        }

        const actions = [];

        if (error.action) {
            actions.push(parseErrorAction(error));
        }

        showFlash({
            text: flash.message,
            type: flash.type as Noty.Type,
            timeout: flash.timeout,
            actions: actions
        });

        if (flash.stop) {
            return true;
        }

    } else {
        store.dispatch("ErrorFlash", error.text, { root: true });
    }

    return false;
}

function getCodeError(error: ErrorResponse): ErrorEntity {
    if (errorMessages[error.group] && errorMessages[error.group][error.code]) {
        return errorMessages[error.group][error.code];
    }

    return { message: "Неизвестная ошибка" };
}

function parseErrorAction(error: ErrorResponse): IFlashAction {
    switch (error.action.action) {
        case 'show-login-form':
            return {
                callback: (noty) => {
                    window.$nuxt?.$store.commit('showLoginForm', error.action.params);
                    noty.close();
                },
                class: 'btn white sm',
                text: 'Войти'
            }
    }
}