import { Component, Vue, Watch as watch} from 'nuxt-property-decorator';
import { State} from 'vuex-class';
import { faYandex, faOdnoklassniki, faVk, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { showErrorFlash, showFlash } from '~/lib/flash';


@Component({
    components: {
        popup: require("~/components/ui/dialog").default,
        phoneForm: require('~/components/pages/login/phone').default,
        emailForm: require('~/components/pages/login/email').default,
    },
    fetchOnServer: false,
})
export default class LoginFormComponent extends Vue {

    @State("showLoginForm")
    private show!: boolean;
    @State("loginFormParams")
    private params!: any;

    public close() {
        this.$store.commit('setLoginFormState', false);
    }

    type = false;
}
