import { RestApiRequest } from '../rest';
import '@nuxtjs/axios';
import { Store } from 'vuex';

function fetcherFactory<T>(action: string) {
    return async function (this: Store<any>, store, params) {
        const response = await RestApiRequest<T>({
            name: action,
            params,
        }, store, this.$axios);
    
        if (response.status === 200) {
            return response.data;
        }
    };
}


export async function GetCityList(this: Store<any>, store) {

    // незачем повторно получать список городов
    if (store.state.cityList) {
        return;
    }

    const response = await RestApiRequest<City[]>({
        name: 'citySelector',
    }, store, this.$axios);

    if (response.status === 200) {
        store.commit('setCityList', response.data);
    }
}

export const FetchOffer = fetcherFactory('product');
export const FetchProductList = fetcherFactory('productList');
export const FetchProductListPage = fetcherFactory('productListPage');
export const FetchCouchConfigurator = fetcherFactory('couchConfigurator');

export async function FetchIndex(this: Store<any>, store) {

    const response = await RestApiRequest<indexPageResponse>({
        name: 'indexPage',
        params: {test: true}
    }, store, this.$axios);

    return response.data;
}

export async function FetchTextPage(this: Store<any>, store, params) {

    const response = await RestApiRequest<TextPageResponse>({
        name: 'textPage',
        params,
    }, store, this.$axios);

    return response.data;
}

export async function FetchLanding(this: Store<any>, store, params) {

    const response = await RestApiRequest({
        name: 'landing',
        params,
    }, store, this.$axios);

    return response.data;
}

export async function FetchRetailStores(this: Store<any>, store, params) {

    const response = await RestApiRequest({
        name: 'retailStores',
        params
    }, store, this.$axios);

    return response.data;
}

export async function FetchDeliveryInfo(this: Store<any>, store) {

    const response = await RestApiRequest({
        name: 'deliveryInfo',
    }, store, this.$axios);

    return response.data;
}

export async function FetchRegionRequisite(this: Store<any>, store) {

    const response = await RestApiRequest({
        name: 'regionRequisites',
    }, store, this.$axios);

    return response.data;
}

export async function FetchSuggestedCoupons(this: Store<any>, store) {

    const response = await RestApiRequest({
        name: 'suggestedCoupons',
    }, store, this.$axios);

    return response.data;
}

/**
 * @param this 
 * @param store 
 * @param params 
 * @returns вакансии в городе 
 */
export async function FetchVacancies(this: Store<any>, store, params) {

    const response = await RestApiRequest({
        name: 'cityVacancies',
        params
    }, store, this.$axios);

    return response.data;
}



export async function GetWidget(this: Store<any>, store, params) {

    const response = await RestApiRequest({
        name: 'widget',
        params
    }, store, this.$axios);

    return response.data;
}

