import Noty from 'noty';
import { IFlashInfo } from '~/types/flash';


export function showFlash(flash: IFlashInfo) {

    let noty: Noty = null;

    let buttons = [];

    if (Array.isArray(flash.actions)) {
        buttons = flash.actions.map((el) => Noty.button(el.text, el.class, () => {
            el.callback(noty);
        }))
    }

    noty = new Noty({
        layout: 'topRight',
        theme: 'metroui',
        type: flash.type || 'error',
        timeout: flash.timeout,
        text: flash.text,
        buttons: buttons
    });

    noty.show();

    if (flash.link && window.$nuxt) {
        noty.on('onClick', () => {
            if (window.$nuxt) {
                window.$nuxt.$router.push(flash.link);
            }
        });
    }
}

export function showErrorFlash(text: string) {
    new Noty({
        layout: 'topRight',
        theme: 'metroui',
        type: 'error',
        timeout: 3000,
        text: text,
    }).show();
}
