import { Vue, Component, Prop as prop } from 'nuxt-property-decorator';
import { CreateElement } from 'vue';

@Component
export default class ErrorPageComponent extends Vue {

    @prop()
    error;

    head() {
        let title = 'Ошибка!';

        if (this.message.title) {
            title = this.message.title;
        } else {
            switch (this.error.statusCode) {
                case 403:
                    title = 'Недоступно - Интернет-магазин мебели «ТриЯ»'; break;
                case 404:
                    title = 'Страница не найдена - Интернет-магазин мебели «ТриЯ»'; break;
                case 500:
                    title = 'Ошибка сервера - Интернет-магазин мебели «ТриЯ»'; break;
            }
        }

        return {
            title,
        }
    }

    render(h: CreateElement) {
        return <div class="page-not-found-block">
            <div class="danger-line"></div>
            <div class="inner">
                <div class="background">
                    {this.error.statusCode}
                </div>
                <div class="caption-container">
                    <div class="caption">
                        {this.text}
                    </div>
                </div>
            </div>
            <div class="danger-line"></div>
            <div class="appologies">
                <div>{this.description}</div>
                <nuxt-link to="/">Вернуться на главную</nuxt-link>
            </div>
        </div>
    }

    get text() {
        if (this.message.text) {
            return this.message.text;
        } else {
            switch (this.error.statusCode) {
                case 403:
                    return 'Страница недоступна';
                case 404:
                    return 'Страница не найдена....';
                case 500:
                    return 'Ошибка сервера';
            }
        }
    }

    get description() {
        if (this.message.description) {
            return this.message.description;
        } else {
            switch (this.error.statusCode) {
                case 404:
                    return 'Неправильно набран адрес или такой страницы не существует.';
                case 500:
                    return 'Проблема у нас, а не у Вас.\n'
                        + 'Мы уже делаем всё,\n'
                        + 'чтобы это исправить!';
            }
        }
        return null;
    }

    get message() {

        try {
            return JSON.parse(this.error.message);
        } catch(e) {
            return {
                title: null,
                text: null,
                description: null,
            };
        }
    }
}
