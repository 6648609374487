import { Component, Vue, Watch as watch, Prop as prop } from 'nuxt-property-decorator';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'

@Component
export default class DialogComponent extends Vue {

    @prop() public title;
    @prop({type: Boolean, default: false}) public value;
    @prop() public innerClass;

    @watch('$route')
    public close() {
        this.$emit("input", false);
    }

    @watch('value')
    private scrollMatcher() {
        if (typeof document !== 'undefined') {
            if (this.value) {
                document.body.classList.add('no-scroll');
                this.$store.dispatch('CloseMenu');
            } else {
                document.body.classList.remove('no-scroll');
            }
        }
        console.log(this.value)
    }

    get arrowIcon() {
        return faTimes;
    }
}
