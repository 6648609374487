import { Context } from "@nuxt/types";

export default async function (ctx: Context) {

    if (!ctx.store.state.region) {
        try {
            await ctx.store.dispatch('FetchInitialInfo');
        } catch (err) {
            ctx.error({
                statusCode: 500,
                message: 'dead',
            });
        }
    }

    if (!ctx.store.state.user) {

        if (/^\/personal\/cart\/(\d+)\//.test(ctx.route.path) && typeof ctx.route.query.key === 'string' && ctx.route.query.key.length === 32) {
            return;
        }

        const url = ctx.req.url;
        ctx.redirect(`/login/?backurl=${encodeURI(url)}`);
    }
}