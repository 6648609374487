// this function will work cross-browser for loading scripts asynchronously
export function loadScript(src: string) {
    return new Promise<void>(function (resolve, reject) {
        const s = document.createElement("script");
        s.type = "text/javascript";
        s.src = src;
        s.async = true;
        s.onerror = function (err) {
            reject(err);
        };
        s.onload = function () {
            resolve();
        };
        const t = document.getElementsByTagName("script")[0];
        t.parentElement!.insertBefore(s, t);
    });
}
