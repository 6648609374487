import { Component, Vue, Prop as prop} from 'nuxt-property-decorator';
import { mapState, mapGetters } from 'vuex';

@Component({
    computed: mapState(['user', 'region'])
})
export default class AppFooterComponent extends Vue {

    @prop({default: true})
    public showSubscribe: boolean;

    private region: Store.CurrentRegion;

    get phones() {
        return this.region.phones.join(', ');
    }

    get socialIcons() {

        return [{
            href: 'https://t.me/triyaru',
            icon: require('~/assets/icons/social/tg.svg'),
        }, {
            href: 'https://vk.com/triyaru',
            icon: require('~/assets/icons/social/vk.svg'),
        }, {
            href: 'https://www.odnoklassniki.ru/triyaru',
            icon: require('~/assets/icons/social/ok.svg'),
        }];
    }
}
