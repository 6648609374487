import { Component, Vue} from 'nuxt-property-decorator';
import {mapGetters, mapState} from 'vuex';

@Component({
    components: {
        loader: require('~/components/common/loader-block').default,
        shadedContainer: require('~/components/common/shaded-container').default,
    },
    computed: {
        ...mapGetters(['region']),
        ...mapState('data', ['cityList']),
    },
})
export default class CitySelectorComponent extends Vue {

    public searchText: string = '';
    public activeRegion: string = 'www';
    public region: string;
    public cityList: City[];

    private topCityNames = new Set(['www.triya.ru', 'spb.triya.ru']);

    private loading = false;

    public created() {
        if (!this.cityList) {

            this.loading = true;

            this.$store.dispatch('data/GetCityList').then(() => {
                this.loading = false;
            });
        }
    }

    public mounted() {
        this.activeRegion = this.region;
    }

    public async setCity(city, code) {
        this.activeRegion = city;

        await this.$store.dispatch('ChangeRegion', {city, code});

        this.$emit('close');
    }

    get displayList(): City[] {

        if (!this.cityList) {
            return [];
        }

        const query = this.searchText.toLocaleLowerCase().trim();

        if (query === '') {
            return this.cityList.filter((el) => el.top).sort((a, b) => a.name.localeCompare(b.name));
        } else {
            return this.cityList.filter((el) => el.name.toLocaleLowerCase().includes(query)).sort((a, b) => a.name.localeCompare(b.name));
        }
    }

    get topCities(): City[] {
        if (!this.cityList) {
            return [];
        }

        return this.cityList.filter((el) => el.top && this.topCityNames.has(el.region)).sort((a, b) => a.name.localeCompare(b.name));
    }

    get cityColumns(): City[][] {

        if (!this.cityList) {
            return [];
        }

        

        let cityList: City[] = [];

        const query = this.searchText.toLocaleLowerCase().trim();
        if (query === '') {
            cityList = this.cityList.filter((el) => el.top && !this.topCityNames.has(el.region));
        } else {
            cityList = this.cityList.filter((el) => el.name.toLocaleLowerCase().includes(query));
        }

        if (!cityList.length) {
            return [];
        }

        cityList = cityList.sort((a, b) => a.name.localeCompare(b.name));

        let lastLetter = null;

        cityList.forEach((el) => {
            el.showTop = el.name[0] !== lastLetter;
            lastLetter = el.name[0];
        });


        let l = Math.trunc(cityList.length / 4);

        if (l * 4 < cityList.length) {
            l++;
        }

        let offset = 0;

        const result = [];

        for (let i = 0; i < 4; i++) {
            result.push(cityList.slice(offset, l + offset));
            offset += l;
        }
        
        return result;
    }
}
