import * as cookies from 'js-cookie';

export function setFlash(state: Store.RootState, value: Store.FlashState) {
    state.flash = value;
}

export function setMenuShow(state: Store.RootState, value: boolean) {
    state.showMenu = value;
}

export function setCurrentRegion(state: Store.RootState, value: Store.CurrentRegion) {

    if (!value) {
        return;
    }

    if (!state.region || state.region.code !== value.code) {
        state.region = value;
    }
}

export function setUser(state: Store.RootState, value) {
    state.user = value;
}

export function pushInHistory(state: Store.RootState, value) {
    state.history.push(value);
}

export function setRemoteAddress(state: Store.RootState, value) {
    state.remoteAddress = value;
}

export function reviveToken(state: Store.RootState) {
    state.token = cookies.get('_csfr');
}

export function setToken(state: Store.RootState, token) {
    state.token = token;
}

export function beforeNewPage(state: Store.RootState) {
    state.shouldCleanBreadcrumbs = true;
}

export function setLoginFormState(state: Store.RootState, value: boolean) {
    state.showLoginForm = value;
    state.loginFormParams = null;
}

export function showLoginForm(state: Store.RootState, value: any = null) {
    state.showLoginForm = true;
    state.loginFormParams = value;
}

export function setBreadcrumbs(state: Store.RootState, value) {
    state.breadcrumbs = value;
    state.shouldCleanBreadcrumbs = false;
}

export function loadReсentSearch(state: Store.RootState, query: string) {
    if (typeof localStorage === 'undefined') {
       return;
    }

    const item = localStorage.getItem('recent-search');

    if (!item) {
        return;
    }

    try {
        state.recentSearch = JSON.parse(item);
    } catch {
        localStorage.removeItem('recent-search');
    }
}

export function addReсentSearch(state: Store.RootState, query: string) {
    if (typeof localStorage === 'undefined') {
       return;
    }

    // если такой запрос у нас уже был в обозримом прошлом, то просто поднимаем его наверх
    if (state.recentSearch.includes(query)) {
        const idx = state.recentSearch.indexOf(query);
        state.recentSearch.splice(idx, 1);
        state.recentSearch.unshift(query);
    } else {
        state.recentSearch.unshift(query);
        state.recentSearch = state.recentSearch.slice(0, 10);
    }
 
    localStorage.setItem('recent-search', JSON.stringify(state.recentSearch));
}

export function removeReсentSearch(state: Store.RootState, query: string) {
    if (typeof localStorage === 'undefined') {
       return;
    }

    if (state.recentSearch.includes(query)) {
        const idx = state.recentSearch.indexOf(query);
        state.recentSearch.splice(idx, 1);
    }
 
    localStorage.setItem('recent-search', JSON.stringify(state.recentSearch));
}
