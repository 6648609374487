import { Context } from "@nuxt/types";

export default async function (ctx: Context) {

    let firstLoading = true;

    ctx.app.router.beforeEach((to, from, next) => {
        if (from.path !== to.path) {
            ctx.store.commit('beforeNewPage');
        }
        next();
    });
    ctx.app.router.afterEach((to, from) => {

        to.matched.forEach((item) => {
            if (item?.components && typeof (item.components.default as any).options.breadcrumbs !== 'undefined') {

                let breadcrumbs = (item.components.default as any).options.breadcrumbs;

                if (typeof breadcrumbs === 'function') {
                    breadcrumbs = breadcrumbs(ctx);
                }

                if (Array.isArray(breadcrumbs) && breadcrumbs.length) {
                    ctx.store.commit('setBreadcrumbs', breadcrumbs);
                }
            } else if (ctx.store.state.shouldCleanBreadcrumbs && !firstLoading && from.path !== to.path) {
                ctx.store.commit('setBreadcrumbs', []);
            }
        });

        firstLoading = false;
    });
}