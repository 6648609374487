import { RestApiFormRequest, RestApiRequest } from './rest';
import cookies from 'js-cookie';
import { ActionContext } from 'vuex';
import '@nuxtjs/axios';
import { Store } from 'vuex';
import { Context } from 'mocha';
import { isEqual } from 'lodash';

type actionState = ActionContext<Store.RootState, Store.RootState>;

export function SetFlash(store: actionState, action) {
    store.commit('setFlash', action);
}

export function SuccessFlash(store: actionState, message: string) {

    const flash: Store.FlashState = {
        message,
        timeout: 3000,
        type: 'success',
    };

    store.commit('setFlash', flash);
}

export function ErrorFlash(store: actionState, message: string) {

    const flash: Store.FlashState = {
        message,
        timeout: 3000,
        type: 'error',
    };

    store.commit('setFlash', flash);
}

export function CloseFlash(store: actionState) {
    store.commit('setFlash', null);
}

export function OpenMenu(store: actionState) {
    store.commit('setMenuShow', true);
}

export function CloseMenu(store: actionState) {
    store.commit('setMenuShow', false);
}

export async function FetchInitialInfo(this: Store<any>, store: actionState) {
    const response = await RestApiRequest<InitialRequestResponse>({
        name: 'initialRequest',
    }, store, this.$axios);

    if (response.status === 200) {
        store.state.favoriteCount = response.data.favorites;
        store.commit('setUser', response.data.user);
        store.commit('setCurrentRegion', response.data.region);
        store.commit('cart/setCart', response.data.cart);
        store.commit('data/setMainMenu', response.data.menu);
    }
}

export async function FetchWatchInfo(this: Store<any>, store: actionState) {

    const data = await RestApiRequest<WatchRequestResponse>({
        name: 'watchRequest',
    }, store, this.$axios);

    store.state.favoriteCount = data.data.favorites;

    if (store.getters.region !== data.data.region) {
        await store.dispatch('FetchInitialInfo');
    } else if (!isEqual(store.state.user, data.data.user)) {
        await store.dispatch('FetchInitialInfo');
    } else if (store.state.cart.payType !== data.data.cart.payType
        || store.state.cart.coupon !== data.data.cart.coupon
        || !compareCartContent(store.state.cart.cart, data.data.cart.content)) {
        await store.dispatch('FetchInitialInfo');
    }
}

export async function FetchPageDescriptions(this: Store<any>, store: actionState, params) {

    return await RestApiRequest({
        name: 'descriptions',
        params,
    }, store, this.$axios);
}

export async function UserLogin(this: Store<any>, store: actionState, params) {

    try {
        const response = await RestApiRequest({
            name: 'login',
            params,
        }, store, this.$axios);

        store.commit('setUser', response.data);

        if (window.location && (response.data as any)?.restricted) {
            document.location.replace('https://old.triya.ru');
        }

        store.dispatch('cart/Get');

    } catch (error) {
        throw error;
    }
}

export async function UserLoginByPhone(this: Store<any>, store: actionState, params) {

    try {
        const response = await RestApiRequest({
            name: 'loginByPhone',
            params,
        }, store, this.$axios);

        store.commit('setUser', response.data);

        store.dispatch('cart/Get');

    } catch (error) {
        throw error;
    }
}


export async function UserOauthLogin(this: Store<any>, store: actionState, {provider, params}) {
    try {
        const response = await RestApiRequest({
            name: 'oauth-' + provider,
            params,
        }, store, this.$axios);

        store.commit('setUser', response.data);

        store.dispatch('cart/Get');

    } catch (error) {
        throw error;
    }
}

export async function GetOauthProviders(this: Store<any>, store: actionState) {

    const response = await RestApiRequest({
        name: 'getOauthProviders',
    }, store, this.$axios);

    if (response.status === 200) {
        return response.data;
    } else {
        throw Error('some error');
    }
}

export async function UserRegister(this: Store<any>, store: actionState, params) {

    try {
        const response = await RestApiRequest({
            name: 'register',
            params,
        }, store, this.$axios);

        store.commit('setUser', response.data);

        store.dispatch('cart/Get');

    } catch (error) {
        throw error;
    }
}

export async function UserLogout(this: Store<any>, store: actionState) {

    await RestApiRequest({
        name: 'logout',
    }, store, this.$axios);
    store.commit('setUser', null);

    store.dispatch('cart/Get');
}

export async function ChangeUserInfo(this: Store<any>, store: actionState, params) {

    try {
        const response = await RestApiRequest({
            name: 'changeUserInfo',
            params,
        }, store, this.$axios);

        store.commit('setUser', response.data);

        SuccessFlash(store, 'Сохранено');

    } catch (error) {
        ErrorFlash(store, 'Ошибка сохранения');
        throw error;
    }
}

export async function ChangeRegion(store: actionState, region: {city: string, code: string}) {

    if (cookies.get('sub_region') !== region.code) {
        cookies.remove('sub_region');

        cookies.set('sub_region', region.code, {
            domain: '.triya.ru',
            expires: 90,
        });
    }

    if (cookies.get('regions') !== region.city) {

        cookies.remove('regions');

        cookies.set('regions', region.city, {
            domain: '.triya.ru',
            expires: 90,
        });
        await store.dispatch('FetchInitialInfo');
    }
}

export async function RestRequest(this: Store<any>, store: actionState, {url, params}) {

    const response = await RestApiRequest({url: `/rest${url}`, params,}, store, this.$axios);
    if (response.status === 200) {
        return response.data;
    } else if (response.status === 204) {
        return;
    } else {
        throw Error('some error');
    }
}
export async function RestFormRequest(this: Store<any>, store: actionState, {url, params}) {

    const response = await RestApiFormRequest({url: `/rest${url}`, params,}, store, this.$axios);
    if (response.status === 200) {
        return response.data;
    } else if (response.status === 204) {
        return;
    } else {
        throw Error('some error');
    }
}

// export function switchToFullVersion() {

//     cookies.set('device', 'desktop', {
//         domain: '.dev-triya.ru',
//         expires: 90,
//     });
// }

export async function nuxtServerInit({dispatch, commit, state}, ctx: Context) {

    /**
     * Такая тема. Нужно совершить 1-2 разных запроса к апи до того как отрендерится страница
     * 1 из них выполнятся всегда при первой загрузке страницы, так-что логично будет запихать его
     * в nuxtServerInit, вместо расписывания его логики в каждом компоненте.
     * Но, nuxt ждет выполнения промиса nuxtServerInit до того как он начнет вызывать asyncData в компонентах
     * А хочется, чтобы запросы шли одновременно, так быстрее
     *
     * Поэтому дальше случается лютая дичь.
     * Мы определяем есть ли в компоненте, который мы хотим загрузить asyncData
     * И если он там есть, то 1 обращение к апи вызовет 2 запроса одновременно
     * А если его нет, то мы получим что хотим здесь (ведь больше негде)
     */

    let needInitStore = true;

    if (ctx.route.matched.length) {

        if (state.region !== null) {
            needInitStore = false;
        } else {
            const matched = ctx.route.matched[0];

            if (typeof matched.components.default.options.asyncData !== 'undefined') {
                needInitStore = false;
            }
        }
    }

    commit('setRemoteAddress', ctx.req.headers['x-forwarded-for'] || ctx.req.connection.remoteAddress);

    if (needInitStore) {

        try {
            await dispatch('FetchInitialInfo');
        } catch (err) {
            ctx.error({
                statusCode: 500,
                message: 'dead',
            });
        }
    }
}

function compareCartContent(presented: CartItem[], incoming: CartItem[]): boolean {

    if (Array.isArray(presented) !== Array.isArray(incoming)) {
        return false;
    } else if (presented.length !== incoming.length) {
        return false;
    }

    const stored = new Map<number, number>();
    const newItems = new Map<number, number>();

    for (const item of presented) {
        if (!stored.has(item.offer)) {
            stored.set(item.offer, item.quantity * item.price);
        } else {
            stored.set(item.offer, stored.get(item.offer) + item.quantity * item.price);
        }
    }

    for (const item of incoming) {
        if (!newItems.has(item.offer)) {
            newItems.set(item.offer, item.quantity * item.price);
        } else {
            newItems.set(item.offer, newItems.get(item.offer) + item.quantity * item.price);
        }
    }

    for (const [offer, price] of stored.entries()) {
        if (!newItems.has(offer) || newItems.get(offer) !== price) {
            return false;
        }
    }

    for (const [offer, price] of newItems.entries()) {
        if (!stored.has(offer) || stored.get(offer) !== price) {
            return false;
        }
    }
    return true;
}
