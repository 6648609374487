const middleware = {}

middleware['authorized'] = require('../middleware/authorized.ts')
middleware['authorized'] = middleware['authorized'].default || middleware['authorized']

middleware['clean-breadcrumbs'] = require('../middleware/clean-breadcrumbs.ts')
middleware['clean-breadcrumbs'] = middleware['clean-breadcrumbs'].default || middleware['clean-breadcrumbs']

middleware['save-history'] = require('../middleware/save-history.ts')
middleware['save-history'] = middleware['save-history'].default || middleware['save-history']

export default middleware
