import { Component, Vue, Watch as watch} from 'nuxt-property-decorator';
import {mapGetters} from 'vuex';
import { State as state } from 'vuex-class';
import debounce from 'lodash/debounce';
import { DebouncedFunc } from 'lodash';
@Component({
    components: {
        customPicture: require('~/components/common/image/').default,
    },
    computed: mapGetters(['region']),
})
export default class SuggestSearchComponent extends Vue {

    public searchQuery: string = '';
    public searchResult: OfferList[] = [];
    private loading = false;
    private active = false;
    private debouncedSearch: DebouncedFunc<(query: string) => Promise<void>> = null;

    public async search(search: string) {

        if (search.length < 3 || this.loading) {
            this.searchResult = [];
            return;
        }

        this.loading = true;

        try {
            const data = await this.$store.dispatch('catalog/Search', {
                search,
                mini: true,
            });

            this.searchResult = data.offers;
        } catch (err) {
            console.error(err);

            this.searchResult = [];
        }

        this.loading = false;

        // если строка запроса изменилась, пока мы искали
        if (search !== this.searchQuery) {
            // то мы ищем снова
            this.search(this.searchQuery);
        }
    }

    protected searchNow(query?: string) {

        this.searchQuery = query;
        this.search(this.searchQuery);
        this.$nextTick(() => {
            this.debouncedSearch?.cancel();
        });
    }

    @watch('searchQuery')
    @watch('region')
    protected OnSearchQueryChanged(query: string) {
        if (!this.debouncedSearch) {
            this.debouncedSearch = debounce(this.search, 500);
        }
        this.debouncedSearch(this.searchQuery);
    }

    openSearchPage() {
        this.$router.push(this.searchLink);
    }

    get searchLink() {
        return '/search/?q=' + encodeURI(this.searchQuery.trim());
    }
}
