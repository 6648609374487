
export function cartPersonalInfo(state) {

    let val = null;

    try {
        val = JSON.parse(localStorage.getItem('cartPersonalInfo'));
    } catch {
        val = state.cartPersonalInfo;
    }

    return val;
}

export function expressDeliveryStatus(state: Store.Cart.State) {
    const remainsMap = new Map<number, number>();

    state.offers.forEach((value) => {
        remainsMap.set(value.id, value.express);
    });

    state.cart.forEach((item) => {
        remainsMap.set(item.offer, (remainsMap.get(item.offer) || 0) - item.quantity);
    });

    const remains = Array.from(remainsMap.values());

    return [remains.findIndex((el) => el >= 0) !== -1, remains.findIndex((el) => el < 0) !== -1];
}

export function selectedServices(state: Store.Cart.State): CartSelectedService[] {

    if (typeof sessionStorage !== 'undefined' && !state.selectedServices) {
        const item = sessionStorage.getItem('cart-selected-services-v2');

        if (item) {

            const services = JSON.parse(item) as CartSelectedService[];

            if (services) {
                return services;
            }
        }
    }

    return state.selectedServices || [];
}

export function personalInfo(state: Store.Cart.State): Store.Cart.PersonalInfo {

    if (typeof sessionStorage !== 'undefined' && !state.personalInfo) {
        const item = sessionStorage.getItem('cart-personal-info');

        if (item) {
            return (JSON.parse(item) as Store.Cart.PersonalInfo);
        }
    }

    return state.personalInfo || {
        name: '',
        surname: '',
        phone: '',
        email: '',
        address: '',
    };
}

export function services(state) {

    return state.services;
}
