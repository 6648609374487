import Vue from 'vue';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import { library, config } from '@fortawesome/fontawesome-svg-core';
import { faEye, faEyeSlash, faTimes, faCaretDown, faChevronDown, faListUl, faHeart, faCameraRetro, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { faHeart as hLight, faCircle, faPlayCircle } from '@fortawesome/free-regular-svg-icons';


config.autoAddCss = false;
library.add(faCaretDown, faTimes, faEyeSlash, faEye, faChevronDown, faListUl, faHeart, hLight, faCameraRetro, faCircle, faExclamation, faPlayCircle);

Vue.component('fa-icon', (FontAwesomeIcon as any))
Vue.component('fa-layer', (FontAwesomeLayers as any))
