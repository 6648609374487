
import { CreateElement, RenderContext } from 'vue';

export default {
    functional: true,
    render(h: CreateElement, ctx: RenderContext) {

        const svg = h('svg', {
            class: 'circular',
            attrs: {
                viewBox: '25 25 50 50'
            }
        }, [
            h('circle', {
                class: 'path',
                attrs: {
                    cx: 50,
                    cy: 50,
                    r: 20,
                    fill: 'none',
                    'stroke-miterlimit': 10
                }
            })
        ]);

        return h('div', {
            class: 'loader-showbox ' + ctx.data.class,
            style: ctx.data.style,
        }, [
            h('div', {
                class: 'loader'
            }, [svg])
        ])
    }
}
