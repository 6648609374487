import { CreateElement, RenderContext } from 'vue';

const loading = require('~/components/common/loader/').default;

export default {
    functional: true,
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'button'
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    render(h: CreateElement, ctx: RenderContext) {

        let childrens = [...ctx.children];

        const classes = ['btn', ctx.data.staticClass];

        const attrs = ctx.data.attrs || {};

        if (ctx.props.loading) {
            childrens.push(h(loading, {class: 'any-size fit'}));
            classes.push('loading');
        }

        if (ctx.props.disabled) {
            classes.push('disabled');
            attrs['disabled'] = true;
        }

        attrs['type'] = ctx.props.type;

        return h('button', {
            class: classes,
            attrs,
            on: {
                click() {
                    if (ctx.listeners.click && !ctx.props.loading && !ctx.props.disabled) {
                        if (Array.isArray(ctx.listeners.click)) {
                            for (const callback of ctx.listeners.click) {
                                callback();
                            }
                        } else {
                            ctx.listeners.click();
                        }
                    }
                }
            }
        }, childrens);
    }
}