const state: () => Store.Cart.State = () => ({
    cart: null,
    offers: null,
    services: null,
    coupon: null,
    plannedDelivery: null,
    payType: 1,
    personalInfo: null,
    selectedServices: null,
    updatingItems: new Set(),
});

export default state;
