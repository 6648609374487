import { Context } from "@nuxt/types";

export default async function (ctx: Context) {
    if (ctx.route.path[ctx.route.path.length - 1] !== '/') {
        ctx.redirect(301, ctx.route.path + '/', ctx.route.query);
    }

    const existsIdx = ctx.store.state.history.indexOf(ctx.route.fullPath);
    if (existsIdx !== -1) {
        ctx.store.state.history.splice(existsIdx, 1);
    }

    ctx.store.state.history.push(ctx.route.fullPath);
}