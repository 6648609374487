import Vue from 'vue'

Number.prototype.toMoney = function(this: number): string {
    return this.toFixed(0).replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
};

Vue.filter('money', (value: number | string) => Number(value).toFixed(0).replace(/(\d)(?=(\d{3})+$)/g, '$1 '));


function getFomattedTime(date: Date) {
    return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
}

Vue.filter('localtime', (value: string) => {
    const date = new Date(value);
    if (date.toDateString() !== new Date().toDateString()) {
        return `${date.toLocaleDateString('ru-RU')}, ${getFomattedTime(date)}`;
    }

    return `Сегодня, ${getFomattedTime(date)}`;
});

Vue.filter('date', (value: string) => {
    return (new Date(value)).toLocaleDateString('ru-RU');
});

Vue.filter('filesize', (value: number) => {
    if (!value) {
        return '';
    }

    const types = ['байт', 'Кб', 'Мб', 'Гб'];

    let type = 0;

    while (value > 1000) {
        value /= 1024;
        type++;
    }

    return `${type ? value.toFixed(1) : value} ${types[type]}`;

});


const extReplaceMap = new Map([
    ['jpg', 'jpeg']
]);

Vue.filter('fileext', (value: string) => {
    if (!value) {
        return '';
    }

    let parts = value.split('.');
    
    if (!parts.length) {
        return '';
    }

    const ext = parts[parts.length - 1].toLowerCase();

    if (extReplaceMap.has(ext)) {
        return extReplaceMap.get(ext);
    }

    return ext;

});

