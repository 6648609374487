import { Component, Vue, Watch as watch} from 'nuxt-property-decorator';
import { State} from 'vuex-class';
import { faYandex, faOdnoklassniki, faVk, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { showErrorFlash, showFlash } from '~/lib/flash';


@Component({
    components: {
        btn: require("~/components/form/button").default,
        formField: require('~/components/form/form-field').default,
    },
})
export default class EmailLoginFormComponent extends Vue {


    login = '';
    password = '';

    public loading = false;

    public close() {
        this.$store.commit('setLoginFormState', false);
    }

    public async sendForm() {
        if (this.loading || this.login.length < 3 || this.password.length < 3) {
            return;
        }

        try {
            this.loading = true;

            await this.$store.dispatch('UserLogin', {
                login: this.login,
                password: this.password,
                remember: true,
            });

            showFlash({
                text: 'Успешная авторизация',
                type: 'success',
                timeout: 3000,
            });
            this.close();
        } catch (error: any) {
            if (!error.handled) {
                showErrorFlash('Неизвестная ошибка');
            }
        } finally {
            this.loading = false;
        }
    }

    saveReturnUrl() {
        sessionStorage.setItem('return-url', document.location.pathname + document.location.search);
    }
}
