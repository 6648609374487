import { Component, Vue, State as state} from 'nuxt-property-decorator';
import { mapState } from 'vuex';

@Component({
    computed: {
        ...mapState(['region']),
    },
    components: {
        sideMenu: require('./side-menu/').default,
        loginForm: require('./login-form/').default,
        cart: require('./cart/').default,
        blackHeader: require('./black-header/').default,
        searchField: require('./search-field/').default,
        breadcrumbs: require('./breadcrumbs').default,
        /** @ts-ignore */
        adminHeader: () => import('./admin-header/index.vue'),
    },
})
export default class HeaderComponent extends Vue {

    @state
    user;
    @state
    favoriteCount;

    public openMenu() {
        this.$store.dispatch('OpenMenu');
    }
}
