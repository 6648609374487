import { Component, Vue, Prop as prop, State} from 'nuxt-property-decorator';
import {validateEmail, validatePhone, validatePassword} from '~/utils';

import { faEye }from '@fortawesome/free-solid-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash';

@Component
export default class FormFieldComponent extends Vue {

    @prop()
    public title: string;

    @prop()
    public type: string;

    @prop()
    public value: string;

    @prop()
    public name: string;

    @prop()
    public tabindex: string;

    @prop({type: Boolean})
    public required: boolean;

    @prop({type: Boolean})
    public textarea: boolean;

    @prop({type: Boolean})
    public disabled: boolean;

    @prop()
    public placeholder: string;

    @prop({default: null})
    public rule?: (string) => boolean;

    @prop({default: 'on'})
    public autocomplete: string;

    @State('region')
    public region: Store.CurrentRegion;

    public invalid: 'empty'|'invalid'|null = null;

    private passwordVisible = false;

    public test(): boolean {

        this.invalid = null;

        if (this.required && (!this.value || !this.value.length)) {

            this.invalid = 'empty';
        } else if (this.value && this.value.length) {

            if (this.rule) {
                if (!this.rule(this.value)) {
                    this.invalid = 'invalid';
                }
            } else {
                let validateFunc: Function | null = null;

                switch (this.type) {
                    case 'email':
                        validateFunc = validateEmail;
                        break;
                    case 'tel':
                        validateFunc = validatePhone;
                        break;
                    case 'password':
                        validateFunc = validatePassword;
                        break;
                    default:
                }

                if (validateFunc && !validateFunc(this.value)) {
                    this.invalid = 'invalid';
                }
            }
        }
        
        this.$emit('invalid', this.invalid);

        return this.invalid === null;
    }

    public focus() {
        if (this.$refs['input']) {
            (this.$refs['input'] as HTMLInputElement).focus();
        }
    }

    public onFocus() {
        this.invalid = null;
    }

    public input(event) {
        this.$emit('input', event.target.value);
    }

    public change(event) {

        this.$nextTick(() => {
            this.test();
        });

        this.$emit('change', event.target.value);
    }

    public toggleVisible(): void {
        this.passwordVisible = !this.passwordVisible;
    }

    get iconClass() {

        if (this.passwordVisible) {
            return faEye;
        }

        return faEyeSlash;
    }

    get passwordIconTabIndex() {
        if (!this.tabindex) {
            return null;
        }

        return Number(this.tabindex) + 1;
    }

    get mask() {

        switch (this.type) {
            case 'tel':
                return `${this.region.country.phoneStart} (###) ###-##-##`;
        }

        return '';
    }

    get computedType() {
        if (this.type !== 'password') {
            return this.type;
        }

        if (this.passwordVisible) {
            return 'text';
        }

        return 'password';
    }

    get fieldType() {
        return this.textarea ? 'textarea' : 'input';
    }
}
