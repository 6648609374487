import { RestApiRequest } from '../rest';
import { ActionContext, Store } from 'vuex';
import '@nuxtjs/axios';

type actionState = ActionContext<null, Store.RootState>;

export async function Catalog(this: Store<any>, store: actionState, params) {

    const response = await RestApiRequest<CatalogDataResponse>({
        name: 'catalog',
        params,
    }, store, this.$axios);

    return response.data;
}

export async function FilteredCatalog(this: Store<any>, store: actionState, params) {

    const response = await RestApiRequest<CatalogDataResponse>({
        name: 'catalogPage',
        params,
    }, store, this.$axios);

    if (response.status === 200) {
        return response.data;
    } else {
        throw Error('some error');
    }
}

export async function ShortedCatalog(this: Store<any>, store: actionState, params) {

    const response = await RestApiRequest({
        name: 'shortedCatalog',
        params,
    }, store, this.$axios);

    if (response.status === 200) {
        return response.data;
    } else {
        throw Error('some error');
    }
}

export async function Actions(this: Store<any>, store: actionState, params) {

    const response = await RestApiRequest<CatalogDataResponse>({
        name: 'actions',
        params,
    }, store, this.$axios);

    return response.data;
}

export async function ActionInfo(this: Store<any>, store: actionState, params) {

    const response = await RestApiRequest<CatalogDataResponse>({
        name: 'actionInfo',
        params,
    }, store, this.$axios);

    if (response.status === 200) {
        return response.data;
    } else {
        throw Error('some error');
    }
}

export async function ActionPage(this: Store<any>, store: actionState, params) {

    const response = await RestApiRequest({
        name: 'actionPage',
        params,
    }, store, this.$axios);

    if (response.status === 200) {
        return response.data;
    } else {
        throw Error('some error');
    }
}

export async function SofaPage(this: Store<any>, store: actionState) {

    const response = await RestApiRequest({
        name: 'sofaPage',
    }, store, this.$axios);

    if (response.status === 200) {
        return response.data;
    } else {
        throw Error('some error');
    }
}

export async function Search(this: Store<any>, store: actionState, params) {

    const response = await RestApiRequest<ListedCatalogResponse>({
        name: 'search',
        params,
    }, store, this.$axios);

    if (response.status === 200) {
        return response.data;
    } else {
        throw Error('some error');
    }
}

export async function Sales(this: Store<any>, store: actionState, params) {

    const response = await RestApiRequest<CatalogDataResponse>({
        name: 'sales',
        params,
    }, store, this.$axios);

    return response.data;
}

export async function SalesPage(this: Store<any>, store: actionState, params) {

    const response = await RestApiRequest<CatalogDataResponse>({
        name: 'salesPage',
        params,
    }, store, this.$axios);

    return response.data;
}

export async function GetReviews(this: Store<any>, store: actionState, params) {

    const response = await RestApiRequest({
        name: 'reviews',
        params,
    }, store, this.$axios);

    if (response.status === 200) {
        return response.data;
    } else {
        throw Error('some error');
    }
}

export async function SendReview(this: Store<any>, store: actionState, params) {

    const response = await RestApiRequest({
        name: 'sendReview',
        params,
    }, store, this.$axios);

    if (response.status === 200) {
        return response.data;
    } else {
        throw Error('some error');
    }
}

export async function CheapRequest(this: Store<any>, store: actionState, params) {

    const response = await RestApiRequest({
        name: 'cheapRequest',
        params,
    }, store, this.$axios);

    if (response.status === 200) {
        return response.data;
    } else {
        throw Error('some error');
    }
}

export async function GetRecentViewList(this: Store<any>, store: actionState, params) {

    const response = await RestApiRequest({
        name: 'getRecentViewList',
        params,
    }, store, this.$axios);

    if (response.status === 200) {
        return response.data;
    } else {
        throw Error('some error');
    }
}

export async function GetFavoriteList(this: Store<any>, store: actionState, params) {

    const response = await RestApiRequest({
        name: 'getFavoriteList',
        params,
    }, store, this.$axios);

    if (response.status === 200) {
        return response.data;
    } else {
        throw Error('some error');
    }
}
