import { RestApiRequest } from '../rest';
import { ActionContext, Store } from 'vuex';
import '@nuxtjs/axios';
import { FavoritesState } from '~/types/store/favorites';
import { clone, debounce } from 'lodash';

type actionState = ActionContext<FavoritesState, Store.RootState>;

export async function ToggleProductFavorite(this: Store<any>, store: actionState, params) {

    const oldValue = store.rootState.favoriteCount;

    if (params.value) {
        store.rootState.favoriteCount++;
    } else {
        store.rootState.favoriteCount--;
    }

    try {
        await RestApiRequest({
            name: 'toggleProductFavorite',
            params,
        }, store, this.$axios);
    } catch (err) {
        store.rootState.favoriteCount = oldValue;
        throw err;
    }
}

export async function CheckProductFavorite(this: Store<FavoritesState>, store: actionState, params) {
    if (!store.state.checkWorker) {
        store.state.checkWorker = debounce(async () => {

            const usedMap = new Map(store.state.checkOffers);
            store.state.checkOffers.clear();

            // console.log(store.state.checkOffers);
            const response = await RestApiRequest({
                url: '/rest/user/check-favorites',
                params: {
                    ids: Array.from(usedMap.keys()),
                }
            }, store, this.$axios);

            Object.entries(response.data).forEach(([id, value]) => {
                const callbacks = usedMap.get(Number(id));
                if (callbacks?.length) {
                    callbacks.forEach((callback) => callback(value));
                }
            })
        }, 100);
    }

    let targetContainer = [];

    if (store.state.checkOffers.has(params.id)) {
        targetContainer = store.state.checkOffers.get(params.id);
    }

    targetContainer.push(params.callback);

    store.state.checkOffers.set(params.id, targetContainer);
    store.state.checkWorker();
}

export async function GetRecentViewList(this: Store<any>, store: actionState, params) {

    const response = await RestApiRequest({
        name: 'getRecentViewList',
        params,
    }, store, this.$axios);

    if (response.status === 200) {
        return response.data;
    } else {
        throw Error('some error');
    }
}

export async function GetFavoriteList(this: Store<any>, store: actionState, params) {

    const response = await RestApiRequest({
        name: 'getFavoriteList',
        params,
    }, store, this.$axios);

    if (response.status === 200) {
        return response.data;
    } else {
        throw Error('some error');
    }
}
