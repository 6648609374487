import { Component, Vue, Watch, Prop as prop} from 'nuxt-property-decorator';
import { loadScript } from '~/lib/script';

@Component
export default class CaptchaComponent extends Vue {
    @prop()
    public value;

    widgetId: any = null;

    mounted() {
        this.init();
    }

    beforeUnmount() {
        if (this.widgetId) {
            window.smartCaptcha.destroy(this.widgetId);
        }
    }

    @Watch('value')
    onWatchChange() {
        if (this.value) {
            window.smartCaptcha.execute(this.widgetId);
        }
    }

    async init() {

        console.log("init")

        await this.tryLoadScript();

        this.widgetId = window.smartCaptcha.render(
            this.$el as HTMLElement,
            {
                sitekey: 'ysc1_11OxJG3WfGXzShzbkSkhEPttmipmUjgEgeICxFtRbbf5c58a',
                invisible: true,
                shieldPosition: 'bottom-left',
                callback: (token) => {
                    this.$emit('token', token);
                    this.$emit('update', false);
                },
            }
        )

    }

    async tryLoadScript() {
        if (!window.smartCaptcha) {
            await loadScript('https://smartcaptcha.yandexcloud.net/captcha.js');
        }
    }
}