
import { CreateElement, RenderContext } from 'vue';

const loader = require('~/components/common/loader/').default;

export default {
    functional: true,
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        fullScreen: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: null,
        }
    },
    render(h: CreateElement, ctx: RenderContext) {

        const overlay = [];

        if (ctx.props.show) {

            let overlayClass = 'loader-overlay';

            if (ctx.props.fullScreen) {
                overlayClass += ' full-screen';
            }

            let loaderEl = null;

            if (ctx.props.color) {
                loaderEl = h(loader, {
                    style: {
                        '--color': ctx.props.color
                    }
                });
            } else {
                loaderEl = h(loader);
            }

            const el = h('div', {
                class: overlayClass
            }, [loaderEl]);

            overlay.push(el);
        }

        const childrens = [
            ...ctx.children,
            h('transition', {
                props: {
                    name: 'block-loader-showing'
                }
            }, overlay)
        ];

        return h('div', {
            class: 'block-loader-container ' + (ctx.data.staticClass || ctx.data.class),
        }, childrens)
    }
}
