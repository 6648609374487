import { AxiosResponse, AxiosInstance, AxiosError }  from 'axios';
import { serialize } from 'object-to-formdata';
import { ActionContext } from 'vuex';
import { handlePublicError } from '~/lib/publicErrorHandler';

export async function RestApiRequest<T>(request: restQuery, store: ActionContext<any, Store.RootState>, axios: AxiosInstance) : Promise<AxiosResponse<T>> {

    if (typeof request.url === 'undefined' && typeof routes[request.name] !== 'string') {
        throw new Error('Undefined route: ' + request.name);
    }

    let initialRequestPromise: Promise<void> = null;

    if (!store.rootState.region && request.name !== 'initialRequest') {
        initialRequestPromise = store.dispatch('FetchInitialInfo', null, {root: true});
    }

    const endpoint = request.url || routes[request.name];

    try {
        const response =  await axios.post<T>(endpoint, {
            remoteAddress: store.rootState.remoteAddress,
            token: store.rootState.token,
            ...request.params,
        });

        return response;

    } catch (error: any) {

        if ((error as AxiosError).response && [404, 422, 500, 501, 503, 504].includes((error as AxiosError).response.status)) {
            console.log({
                route: endpoint,
                addr: store.rootState.remoteAddress,
                params: request.params,
                response: error.response,
            });
        }

        if (error.response && error.response.data) {
            if (typeof error.response.data === 'object' && error.response.data.public_error) {
                error.handled = handlePublicError(error.response.data, store);
            }
        }
        throw error;
    } finally {
        if (initialRequestPromise) {
            await initialRequestPromise;
        }
    }
}


export async function RestApiFormRequest<T = any>(
    request: restQuery,
    store: ActionContext<any, Store.RootState>,
    axios: AxiosInstance
): Promise<AxiosResponse<T>> {
    try {
        const form = serialize(request.params);
        console.log(form)

        return await axios.post(request.url, form, {
            headers: {
                "Content-Type": "multipart/form-data",
                // "X-CSRF-TOKEN": store.rootState.token
            },
            // onUploadProgress: request.progress,
        });
    } catch (error: any) {
        // console.log({
        //     request: request
        // }, error);

        if (error.response?.data) {

            if (error.response.status === 401) {
                window.$nuxt.$router.push('/auth/login');
                return;
            }

            if (
                typeof error.response.data === "object" &&
                error.response.data.public_error
            ) {
                error.handled = handlePublicError(error.response.data, store);
            }
        } else if(window.$nuxt) {
            window.$nuxt.error(error)
        }
        throw error;
    }
}


const routes = {
    initialRequest: '/rest/info',
    watchRequest: '/rest/info/short',
    citySelector: '/rest/info/city-list',
    indexPage: '/rest/page',
    textPage: '/rest/page/text',
    landing: '/rest/landing',
    widget: '/rest/widget',
    
    catalog: '/rest/catalog',
    catalogPage: '/rest/catalog/page',
    shortedCatalog: '/rest/catalog/childs',
    product: '/rest/catalog/product',
    productList: '/rest/catalog/product/list',
    productListPage: '/rest/catalog/product/list/page',
    couchConfigurator: '/rest/catalog/product/couch-configurator',

    cheapRequest: '/rest/request/cheap-request',
    toggleProductFavorite: '/rest/user/toggle-favorite-product',
    getFavoriteList: '/rest/user/favorites',
    getRecentViewList: '/rest/user/recent-view',

    actions: '/rest/catalog/actions',
    actionPage: '/rest/catalog/actions/page',
    actionInfo: '/rest/catalog/actions/info',

    sales: '/rest/catalog/sales',
    salesPage: '/rest/catalog/sales/page',

    sofaPage: '/rest/catalog/sofa',

    search: '/rest/catalog/search',
    reviews: '/rest/catalog/reviews',
    sendReview: '/rest/catalog/reviews/add',

    descriptions: '/rest/seo/meta',

    cart: '/rest/cart',
    add2cart: '/rest/cart/add',
    toggleCartItem: '/rest/cart/toggle',
    updateCartItem: '/rest/cart/update',
    removeCartItems: '/rest/cart/delete-multi',
    setCoupon: '/rest/cart/coupon',
    selectPayType: '/rest/cart/set-pay-type',
    selectServiceCity: '/rest/cart/set-service-city',
    checkout: '/rest/cart/checkout2',
    suggestedCoupons: '/rest/cart/suggested-coupons',
    suggestedCartOffers: '/rest/cart/suggested-offers',

    orderList: '/rest/cart/order/list',
    fetchOrder: '/rest/cart/order/get',
    cancelOrder: '/rest/cart/order/cancel',
    payOrder: '/rest/cart/order/createPayment',
    checkOrderPayment: '/rest/cart/order/confirmPayment',

    deliveryInfo: '/rest/info/delivery',
    retailStores: '/rest/info/retail',
    regionRequisites: '/rest/info/requisites',
    cityVacancies: 'rest/info/vacancies',

    'oauth-vk': '/rest/oauth/vk',
    'oauth-yandex': '/rest/oauth/yandex',

    getOauthProviders: '/rest/oauth/get-list',

    login: '/rest/user/login',
    loginByPhone: '/rest/auth/use-phone-code',
    logout: '/rest/user/logout',
    register: '/rest/user/register',
    changeUserInfo: '/rest/user/update',
    requireUserRestore: '/rest/user/require-password-reset',
    confirmUserRestore: '/rest/user/confirm-password-reset',
    checkUserRestoreToken: '/rest/user/check-password-reset-token',

    FranchisingRequest: '/rest/request/franchising-request',
};
