export default function() {
    (function(w, d, u, i, o, s, p) {
        if (d.getElementById(i)) { return; } w['MangoObject'] = o;
        w[o] = w[o] || function() { (w[o].q = w[o].q || []).push(arguments) }; w[o].u = u; w[o].t = 1 * new Date();
        s = d.createElement('script'); s.async = 1; s.id = i; s.src = u; s.charset = 'utf-8';
        p = d.getElementsByTagName('script')[0]; p.parentNode.insertBefore(s, p);
    }(window, document, '//widgets.mango-office.ru/widgets/mango.js', 'mango-js', 'mgo'));
    mgo({multichannel: {id: 3961}});
    
    (function(d, s) {

        s = document.createElement('script');
        s.async = 1;
        s.src = 'https://cdn.envybox.io/widget/cbk.js?cbk_code=9409eb6c526a381891879a6d387961dc';
        s.charset = 'UTF-8';

        d.body.appendChild(s);

        s = document.createElement( "link" );
        s.href = 'https://cdn.envybox.io/widget/cbk.css';
        s.type = "text/css";
        s.rel = "stylesheet";
        s.media = "screen,print";

        d.body.appendChild(s);
    }(document));
}
