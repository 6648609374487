import { FunctionalComponentOptions, RenderContext } from "vue";

const imageComponent: FunctionalComponentOptions = {
    functional: true,
    props: {
        image: {
            type: Number,
            required: true,
        },
        size: {
            type: String,
            required: false,
        },
        eager: {
            type: Boolean,
            required: false,
        },
    },
    render(h, ctx: RenderContext) {

        const format = ctx.parent.$store.state.imageFormat;

        if (!ctx.props.image) {
             return h('div');
        }

        const id = ctx.props.image.toString();

        const prependPath = id.substring(0, 3).padStart(3, '0');

        const src = '//cdn.triya.ru' + (ctx.props.size ? `/_t/${prependPath}/${ctx.props.image}/${ctx.props.size}.${format}` : `//cdn.triya.ru/_t/${prependPath}/${ctx.props.image}.${format}`)

        return h('img', {
            class: `__picture ${ctx.data.class ?? ''} ${ctx.data.staticClass ?? ''}`.trim(),
            attrs: {
                src,
                loading: ctx.props.eager ? 'eager' : 'lazy'
            }
        });
    }
}

export default imageComponent;
