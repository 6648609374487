import { RestApiRequest } from '../rest';
import { ActionContext, Store } from 'vuex';
import { AxiosInstance } from 'axios';
import '@nuxtjs/axios';
import { showErrorFlash, showFlash } from '~/lib/flash';

type actionState = ActionContext<Store.Cart.State, Store.RootState>;

async function cartRequest(name: string, params: any, store: actionState, axios: AxiosInstance, isBuy: boolean = false): Promise<void> {

    const response = await RestApiRequest<CartContent>({
        name,
        params,
    }, store, axios);

    const data = response.data;

    if (isBuy) {
        data.diff = true;
    }

    store.commit('setCart', data);
}

export function SetItemsUpdate(this: Store<any>, store, {ids, update}: {ids: number[], update: boolean}) {

    const newSet = new Set(store.state.updatingItems);

    if (update) {
        ids.forEach((id) => newSet.add(id));
    } else {
        ids.forEach((id) => newSet.delete(id));
    }

    store.state.updatingItems = newSet;
}

export async function ToggleItem(this: Store<any>, store, params) {

    await cartRequest('toggleCartItem', params, store, this.$axios);
}

export async function Get(this: Store<any>, store: actionState) {

    await cartRequest('cart', null, store, this.$axios);
}

/**
 * Добавление товара в корзину
 * @param store
 * @param param1
 */
export async function Add(this: Store<any>, store, {offerId, quantity}) {

    try {
        await cartRequest('add2cart', {
            id: parseInt(offerId, 10),
            quantity: quantity ?? 1,
        }, store, this.$axios, true);

        showFlash({
            text: 'Товар добавлен в корзину',
            type: 'alert',
            timeout: 3000,
            link: '/personal/cart/'
        });
    } catch (error: any) {
        if (!error.handled) {
            showErrorFlash('Ошибка добавления товара в корзину');
        }
        throw error;
    }
}

/**
 * Удаление товара из корзины
 * @param store
 * @param id
 */
export async function RemoveItem(this: Store<any>, store, id) {

    await RemoveItemBatch.apply(this, [store, [id]]);
}

/**
 * Удаление нескольких товаров из корзины
 * @param store
 * @param ids
 */
export async function RemoveItemBatch(this: Store<any>, store, ids) {

    await cartRequest('removeCartItems', {
        ids,
    }, store, this.$axios);
}

/**
 * Обновление кол-ва товара
 * @param store
 * @param param1
 */
export async function UpdateItem(this: Store<any>, store, {id, quantity}) {

    await cartRequest('updateCartItem', {
        id,
        quantity,
    }, store, this.$axios);
}

/**
 * Обновление кол-ва товара
 * @param store
 * @param param1
 */
export async function SelectPayType(this: Store<any>, store, payType) {

    await cartRequest('selectPayType', {
        payType,
    }, store, this.$axios);
}

export async function SetServiceCity(this: Store<any>, store, city) {

    await cartRequest('selectServiceCity', {
        city,
    }, store, this.$axios);
}

export async function SetCoupon(this: Store<any>, store, coupon) {

    await cartRequest('setCoupon', {
        coupon,
    }, store, this.$axios);
}

/**
 * оформление заказа
 * @param store
 * @param param1
 */
export async function Checkout(this: Store<any>, store: actionState, params) {

    const response = await RestApiRequest<CheckoutResponse>({
        name: 'checkout',
        params,
    }, store, this.$axios);

    if (response.data.user) {

        store.commit('setUser', response.data.user, {
            root: true,
        });
    }

    return response.data;
}

export function getOrder(this: Store<any>, store, params) {

    return RestApiRequest({
        name: 'fetchOrder',
        params,
    }, store, this.$axios);
}

export function cancelOrder(this: Store<any>, store, params) {

    return RestApiRequest({
        name: 'cancelOrder',
        params,
    }, store, this.$axios);
}

export function payOrder(this: Store<any>, store, params) {

    return RestApiRequest({
        name: 'payOrder',
        params: params,
    }, store, this.$axios);
}

export function checkOrderPayment(this: Store<any>, store, params) {

    return RestApiRequest({
        name: 'checkOrderPayment',
        params,
    }, store, this.$axios);
}

export function getOrderList(this: Store<any>, store) {

    return RestApiRequest({
        name: 'orderList',
    }, store, this.$axios);
}

export async function FetchSuggestedOffers(this: Store<any>, store) {

    const response = await RestApiRequest({
        name: 'suggestedCartOffers',
    }, store, this.$axios);

    return response.data;
}

