import { Component, Vue, Watch as watch} from 'nuxt-property-decorator';
import { namespace, State} from 'vuex-class';
import { IMenuData, IMenuItem, IMenuPage, IMenuRootItem } from '~/types/menu';

@Component
export default class SideMenuComponent extends Vue {

    @State(state => state.data.mainMenu)
    private menuData: IMenuData;

    @State("showMenu")
    private showMenu: boolean;

    private mainRect: DOMRect = null;

    private highlightCandidate: IMenuRootItem = null;

    prevMouseEvent: MouseEvent = null;
    switchCaptured = false;
    selectedPage: IMenuRootItem = null;
    switchTimeout: NodeJS.Timeout = null;

    public close() {
        this.selectedPage = null;
        this.prevMouseEvent = null;
        this.switchCaptured = false;
        this.$nextTick(() => {
            this.$store.dispatch('CloseMenu');
        });
    }

    public mounted() {
        this.$nextTick(() => {
            this.attemptFetchRect();
        });
    }

    @watch('showMenu')
    onMenuShow() {
        if (this.showMenu) {
            this.$nextTick(() => {
                this.attemptFetchRect();
            });
        } else {
            this.mainRect = null;
        }
    }

    @watch('$route')
    private onRouterChange($route) {
        this.close();
    }

    onMouseMove(event: MouseEvent) {

        if (!this.prevMouseEvent || !this.mainRect || !this.switchCaptured) {
            return;
        }

        const ydiff = this.prevMouseEvent.y - event.y;

        // движемся к верхнему краю меню
        if (ydiff > 0) {
            const desiredAngle = Math.atan2(this.mainRect.top - this.prevMouseEvent.y, this.mainRect.right - this.prevMouseEvent.x);
            const targetAngle = Math.atan2(this.mainRect.top - event.y, this.mainRect.right - event.x);

            if (desiredAngle < targetAngle) {
                this.setSwitchMissed();
            } else {
                this.updateSwitchTimeout();
            }

        } else if (ydiff < 0) {
            const desiredAngle = Math.atan2(this.mainRect.bottom - this.prevMouseEvent.y, this.mainRect.right - this.prevMouseEvent.x);
            const targetAngle = Math.atan2(this.mainRect.bottom - event.y, this.mainRect.right - event.x);

            if (desiredAngle > targetAngle) {
                this.setSwitchMissed();
            } else {
                this.updateSwitchTimeout();
            }
        } else {
            if (this.prevMouseEvent.x > event.x) {
                this.setSwitchMissed();
            } else {
                this.updateSwitchTimeout();
            }
        }

        this.prevMouseEvent = event;
    }

    declareEnterCandidate(item: IMenuRootItem, event: MouseEvent) {
        this.highlightCandidate = item;
        if (!this.prevMouseEvent) {
            this.prevMouseEvent = event;
        }

        if (!this.switchCaptured) {
            this.switchCaptured = true;
            this.selectedPage = item;
        }
    }

    removeEnterCandidate(item: IMenuRootItem) {
        if (this.highlightCandidate === item) {
            this.highlightCandidate = null;
        }
    }

    setSwitchMissed() {
        if (!this.highlightCandidate) {
            this.switchCaptured = false;
            return;
        }

        if (this.highlightCandidate.key) {
            this.selectedPage = this.highlightCandidate;
        } else {
            this.selectedPage = null
        }
    }

    updateSwitchTimeout() {
        if (this.switchTimeout) {
            clearTimeout(this.switchTimeout);
        }

        this.switchTimeout = setTimeout(() => {
            this.setSwitchMissed();
        }, 150);
    }

    attemptFetchRect() {
        if (!this.showMenu || !this.$refs['mainContainer']) {
            this.mainRect = null;
            return;
        }

        if (!this.fetchRect()) {
            setTimeout(() => {
                this.attemptFetchRect();
            }, 50);
        }
    }


    private fetchRect(): boolean {
        
        if (!this.showMenu || !this.$refs['mainContainer']) {
            this.mainRect = null;
            return true;
        }

        const rect = (this.$refs['mainContainer'] as HTMLElement).getBoundingClientRect();

        if (rect.x === 0) {
            this.mainRect = rect;
            return true;
        }

        return false;
    }

    get activePage(): IMenuPage {
        if (this.selectedPage?.key) {
            return this.menuData.pages[this.selectedPage?.key];
        }

        return null;
    }
}
