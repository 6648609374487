import Vue from 'vue';

(function() {
    let updating = false;

    window.onfocus = async function() {
        if (updating) {
            return;
        }

        updating = true;

        try {
            if (typeof window['$nuxt'] !== undefined && window['$nuxt'] instanceof Vue) {
                await window['$nuxt'].$store.dispatch('FetchWatchInfo');
            }

        } finally {
            updating = false;
        }
    }

})();
