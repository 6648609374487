

export function trackCart(offers: IMetrikaOffer[]) {
    pushTrackInfo('add', offers);
}

export function trackCartAction(offer: IMetrikaOffer) {

    const action = offer.quantity > 0 ? 'add' : 'remove';

    offer.quantity = Math.abs(offer.quantity);

    pushTrackInfo(action, [offer]);
}

export function trackOrder(id: number, offers: IMetrikaOffer[]) {
    pushTrackInfo('purchase', offers, {
        actionField: {
            id
        }
    });
}

export function trackProductView(offer: IMetrikaOffer) {
    pushTrackInfo('detail', [offer]);
}

function pushTrackInfo(action: 'add' | 'remove' | 'detail' | 'purchase', offers: IMetrikaOffer[], additional = {}) {
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
        ecommerce: {
            currencyCode: "RUB",
            [action]: {
                ...additional,
                products: offers
            }
        }
    })
}