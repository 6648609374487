import { Store } from 'vuex';
import { trackCart } from '~/lib/metrika';

export function setCart(state, value: CartContent) {

    const diff = [];

    if (value.content) {
        const content = mathCartContent(state, value.content);

        if (value.diff) {
            const newOffers = findNewOffers(state.cart, content);

            newOffers.forEach((el) => {
                const exists = diff.find((old) => old.id === el.id)
                if (exists) {
                    exists.quantity += el.quantity;
                } else {
                    diff.push(el);
                }
            })
        }

        state.cart = content;
    }

    if (value.services) {
        value.services.services = value.services.services.sort((a, b) => {
            if (a.type === b.type) {
                return a.name.localeCompare(b.name);
            }

            return a.type.localeCompare(b.type);
        });

        state.services = value.services;
    }

    if (value.offers) {
        state.offers = new Map(value.offers.map((el) => [el.id, el]));
    }

    if (diff.length) {
        trackCartDiff(diff, state.offers);
    }

    if (value.payType) {
        state.payType = value.payType;
    }

    state.plannedDelivery = value.delivery;

    state.coupon = value.coupon;
}

export function setPayType(state, value: number) {
    state.payType = value;
}

function findNewOffers(old: CartItem[], newContent: CartItem[]) {
    const oldMap = new Map(old.map((el) => [el.offer, el.quantity]))

    const diff: Partial<CartItem>[] = [];

    newContent.forEach((el) => {
        if (!oldMap.has(el.offer) || oldMap.get(el.offer) < el.quantity) {
            diff.push({
                id: el.offer,
                quantity: el.quantity - (oldMap.get(el.offer) ?? 0),
                price: el.price,
                discount: el.discount
            });
        }
    });

    return diff;
}

function mathCartContent(state, content: CartItem[]) {
    const result = [];

    for (const item of content) {

        const idx = result.findIndex((el: CartItem) => el.price === item.price && el.offer === item.offer);

        if (idx === -1) {
            item.grouped = [item.id];
            item.key = item.id.toString();
            result.push(item);
        } else {
            result[idx].quantity += item.quantity;
            result[idx].grouped.push(item.id);
            item.key = item.offer + '-' + item.price;
        }
    }

    // осуществляем сортировку с целью сгруппировать разные
    // товары с одинаковыми продуктами
    result.sort((a: CartItem, b: CartItem) => (a.offer === b.offer) ? (a.price - b.price) : (a.offer - b.offer));

    return result;
}

export function setSelectedServices(state: Store.Cart.State, value: CartSelectedService[]) {
    state.selectedServices = value;
    if (typeof sessionStorage !== 'undefined') {
        sessionStorage.setItem('cart-selected-services-v2', JSON.stringify(value));
    }
}

export function unselectServices(state: Store.Cart.State, services: CartSelectedService[]) {

    let changed = false;

    if (!state.selectedServices) {
        state.selectedServices = [];
    }

    for (const service of services) {
        const index = state.selectedServices.indexOf(service);

        if (index === -1) {
            continue;
        }

        state.selectedServices.splice(index, 1);
        changed = true;
    } 

    if (changed && typeof sessionStorage !== 'undefined') {
        sessionStorage.setItem('cart-selected-services-v2', JSON.stringify(state.selectedServices));
    }
}

export function setServiceQuantity(this: Store<any>, state: Store.Cart.State, value: {service: CartSelectedService, quantity: number}) {

    if (!state.selectedServices) {
        state.selectedServices = this.getters['cart/selectedServices'];
    }

    const service = state.selectedServices.find((el) => value.service.code === el.code);
    service.quantity = value.quantity;

    if (typeof sessionStorage !== 'undefined') {
        sessionStorage.setItem('cart-selected-services-v2', JSON.stringify(state.selectedServices));
    }
}

export function setCartPersonalInfo(state: Store.Cart.State, value: Store.Cart.PersonalInfo) {
    state.personalInfo = value;
    if (typeof sessionStorage !== undefined) {
        sessionStorage.setItem('cart-personal-info', JSON.stringify(value));
    }
}

export function clear(state) {

    setSelectedServices(state, []);

    state.cart = [];
}

function trackCartDiff(diff: CartItem[], offers: Map<number, CartOffer>) {
    const items = diff.map((el) => {
        const name = offers.get(el.id).name;

        return {
            id: el.id,
            price: el.price,
            name,
            quantity: el.quantity
        }
    });

    trackCart(items);
}