import { RestApiRequest } from '../rest';
import '@nuxtjs/axios';
import { ActionContext, Store } from 'vuex';
import { SuccessFlash, ErrorFlash } from '../actions';

type actionState = ActionContext<Store.RootState, Store.RootState>;

export async function Login(this: Store<any>, store: actionState, params) {

    try {
        const response = await RestApiRequest({
            name: 'login',
            params,
        }, store, this.$axios);

        store.commit('setUser', response.data);

        store.dispatch('cart/Get');

    } catch (error) {
        throw error;
    }
}

export async function Register(this: Store<any>, store: actionState, params) {

    try {
        const response = await RestApiRequest({
            name: 'register',
            params,
        }, store, this.$axios);

        store.commit('setUser', response.data);

        store.dispatch('cart/Get');

    } catch (error) {
        throw error;
    }
}

export async function Logout(this: Store<any>, store: actionState) {

    await RestApiRequest({
        name: 'logout',
    }, store, this.$axios);
    store.commit('setUser', null);

    store.dispatch('cart/Get');
}

export async function ChangeInfo(this: Store<any>, store: actionState, params) {

    try {
        const response = await RestApiRequest({
            name: 'changeUserInfo',
            params,
        }, store, this.$axios);

        store.commit('setUser', response.data);

        SuccessFlash(store, 'Сохранено');

    } catch (error) {
        ErrorFlash(store, 'Ошибка сохранения');
        throw error;
    }
}

export async function RequireRestore(this: Store<any>, store: actionState, params) {

    try {
        const response = await RestApiRequest({
            name: 'requireUserRestore',
            params,
        }, store, this.$axios);

        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function CheckRestoreToken(this: Store<any>, store: actionState, params) {

    try {
        const response = await RestApiRequest({
            name: 'checkUserRestoreToken',
            params,
        }, store, this.$axios);

        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function ConfirmRestore(this: Store<any>, store: actionState, params) {

    try {
        const response = await RestApiRequest({
            name: 'confirmUserRestore',
            params,
        }, store, this.$axios);

        return response.data;
    } catch (error) {
        throw error;
    }
}
