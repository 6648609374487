import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d8ee19ce = () => interopDefault(import('../pages/3d/index.vue' /* webpackChunkName: "pages/3d/index" */))
const _64cf5c9b = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _ed7fb14c = () => interopDefault(import('../pages/about/index/awards.vue' /* webpackChunkName: "pages/about/index/awards" */))
const _087e9c21 = () => interopDefault(import('../pages/about/index/catalogs/index.vue' /* webpackChunkName: "pages/about/index/catalogs/index" */))
const _54ffddba = () => interopDefault(import('../pages/about/index/contacts/index.vue' /* webpackChunkName: "pages/about/index/contacts/index" */))
const _7dc39d6a = () => interopDefault(import('../pages/about/index/deffered-payment.vue' /* webpackChunkName: "pages/about/index/deffered-payment" */))
const _258e441b = () => interopDefault(import('../pages/about/index/delivery/index.vue' /* webpackChunkName: "pages/about/index/delivery/index" */))
const _8307605c = () => interopDefault(import('../pages/about/index/ecology.vue' /* webpackChunkName: "pages/about/index/ecology" */))
const _dd7db246 = () => interopDefault(import('../pages/about/index/production.vue' /* webpackChunkName: "pages/about/index/production" */))
const _ecf36d4e = () => interopDefault(import('../pages/about/index/stores/index.vue' /* webpackChunkName: "pages/about/index/stores/index" */))
const _1677f24a = () => interopDefault(import('../pages/about/index/vacancy/index.vue' /* webpackChunkName: "pages/about/index/vacancy/index" */))
const _f0a81ec0 = () => interopDefault(import('../pages/about/index/warranty.vue' /* webpackChunkName: "pages/about/index/warranty" */))
const _0b3df646 = () => interopDefault(import('../pages/about/index/contacts/central.vue' /* webpackChunkName: "pages/about/index/contacts/central" */))
const _40af4d80 = () => interopDefault(import('../pages/about/index/delivery/credit.vue' /* webpackChunkName: "pages/about/index/delivery/credit" */))
const _7a489282 = () => interopDefault(import('../pages/about/index/delivery/sberbank.vue' /* webpackChunkName: "pages/about/index/delivery/sberbank" */))
const _31ffb54b = () => interopDefault(import('../pages/actions/index.vue' /* webpackChunkName: "pages/actions/index" */))
const _42b19967 = () => interopDefault(import('../pages/catalog/index.vue' /* webpackChunkName: "pages/catalog/index" */))
const _3ba8fc0e = () => interopDefault(import('../pages/grzegorz/index.vue' /* webpackChunkName: "pages/grzegorz/index" */))
const _899cb7ea = () => interopDefault(import('../pages/grzegorz/index/descriptions/index.vue' /* webpackChunkName: "pages/grzegorz/index/descriptions/index" */))
const _aa591b7e = () => interopDefault(import('../pages/grzegorz/index/pending-answers/index.vue' /* webpackChunkName: "pages/grzegorz/index/pending-answers/index" */))
const _d653efac = () => interopDefault(import('../pages/grzegorz/index/product-images/index.vue' /* webpackChunkName: "pages/grzegorz/index/product-images/index" */))
const _33c34414 = () => interopDefault(import('../pages/grzegorz/index/refs/index.vue' /* webpackChunkName: "pages/grzegorz/index/refs/index" */))
const _4c3e87c6 = () => interopDefault(import('../pages/grzegorz/index/reviews/index.vue' /* webpackChunkName: "pages/grzegorz/index/reviews/index" */))
const _df2543c6 = () => interopDefault(import('../pages/grzegorz/index/top-catalog-banners/index.vue' /* webpackChunkName: "pages/grzegorz/index/top-catalog-banners/index" */))
const _3a88e10b = () => interopDefault(import('../pages/grzegorz/index/yandex-payments/index.vue' /* webpackChunkName: "pages/grzegorz/index/yandex-payments/index" */))
const _785fc011 = () => interopDefault(import('../pages/grzegorz/index/index.vue' /* webpackChunkName: "pages/grzegorz/index/index" */))
const _f4898908 = () => interopDefault(import('../pages/grzegorz/index/refs/_id.vue' /* webpackChunkName: "pages/grzegorz/index/refs/_id" */))
const _988dbc76 = () => interopDefault(import('../pages/grzegorz/index/reviews/_id.vue' /* webpackChunkName: "pages/grzegorz/index/reviews/_id" */))
const _6bb80577 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _76a6959b = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _62b80e48 = () => interopDefault(import('../pages/personal/index.vue' /* webpackChunkName: "pages/personal/index" */))
const _4ce48ba6 = () => interopDefault(import('../pages/personal/index/external-auth/index.vue' /* webpackChunkName: "pages/personal/index/external-auth/index" */))
const _17e6e7d3 = () => interopDefault(import('../pages/personal/index/favorites/index.vue' /* webpackChunkName: "pages/personal/index/favorites/index" */))
const _8e82d782 = () => interopDefault(import('../pages/personal/index/orders/index.vue' /* webpackChunkName: "pages/personal/index/orders/index" */))
const _0482becb = () => interopDefault(import('../pages/personal/index/index.vue' /* webpackChunkName: "pages/personal/index/index" */))
const _72b136eb = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _0e52214c = () => interopDefault(import('../pages/sales/index.vue' /* webpackChunkName: "pages/sales/index" */))
const _1cd782f0 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _c87366c8 = () => interopDefault(import('../pages/3d/online.vue' /* webpackChunkName: "pages/3d/online" */))
const _2001e489 = () => interopDefault(import('../pages/about/company/index.vue' /* webpackChunkName: "pages/about/company/index" */))
const _3c78b734 = () => interopDefault(import('../pages/about/franchising/index.vue' /* webpackChunkName: "pages/about/franchising/index" */))
const _7373c91d = () => interopDefault(import('../pages/personal/cart/index.vue' /* webpackChunkName: "pages/personal/cart/index" */))
const _2535a24c = () => interopDefault(import('../pages/personal/password-reset/index.vue' /* webpackChunkName: "pages/personal/password-reset/index" */))
const _41ceda41 = () => interopDefault(import('../pages/promo/credit/index.vue' /* webpackChunkName: "pages/promo/credit/index" */))
const _69799d0c = () => interopDefault(import('../pages/promo/halva/index.vue' /* webpackChunkName: "pages/promo/halva/index" */))
const _788dbc2a = () => interopDefault(import('../pages/about/company/detail.vue' /* webpackChunkName: "pages/about/company/detail" */))
const _82ef2ea6 = () => interopDefault(import('../pages/about/franchising/presentations.vue' /* webpackChunkName: "pages/about/franchising/presentations" */))
const _74b8ce4a = () => interopDefault(import('../pages/personal/payment/sberbank.vue' /* webpackChunkName: "pages/personal/payment/sberbank" */))
const _6e89ba46 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _577c1436 = () => interopDefault(import('../pages/lt/lp/_code/index.vue' /* webpackChunkName: "pages/lt/lp/_code/index" */))
const _0ad90d88 = () => interopDefault(import('../pages/personal/cart/_id/index.vue' /* webpackChunkName: "pages/personal/cart/_id/index" */))
const _00023757 = () => interopDefault(import('../pages/personal/password-reset/_code/index.vue' /* webpackChunkName: "pages/personal/password-reset/_code/index" */))
const _6ca1b540 = () => interopDefault(import('../pages/actions/_path/index.vue' /* webpackChunkName: "pages/actions/_path/index" */))
const _4a87145c = () => interopDefault(import('../pages/catalog/_path/index.vue' /* webpackChunkName: "pages/catalog/_path/index" */))
const _71b1c958 = () => interopDefault(import('../pages/news/_code/index.vue' /* webpackChunkName: "pages/news/_code/index" */))
const _287b4d23 = () => interopDefault(import('../pages/oauth/_provider.vue' /* webpackChunkName: "pages/oauth/_provider" */))
const _cdc69c4c = () => interopDefault(import('../pages/promo/_code/index.vue' /* webpackChunkName: "pages/promo/_code/index" */))
const _104e7359 = () => interopDefault(import('../pages/catalog/_path/_code/index.vue' /* webpackChunkName: "pages/catalog/_path/_code/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/3d/",
    component: _d8ee19ce,
    pathToRegexpOptions: {"strict":true},
    name: "3d"
  }, {
    path: "/about/",
    component: _64cf5c9b,
    pathToRegexpOptions: {"strict":true},
    name: "about",
    children: [{
      path: "awards/",
      component: _ed7fb14c,
      pathToRegexpOptions: {"strict":true},
      name: "about-index-awards"
    }, {
      path: "catalogs/",
      component: _087e9c21,
      pathToRegexpOptions: {"strict":true},
      name: "about-index-catalogs"
    }, {
      path: "contacts/",
      component: _54ffddba,
      pathToRegexpOptions: {"strict":true},
      name: "about-index-contacts"
    }, {
      path: "deffered-payment/",
      component: _7dc39d6a,
      pathToRegexpOptions: {"strict":true},
      name: "about-index-deffered-payment"
    }, {
      path: "delivery/",
      component: _258e441b,
      pathToRegexpOptions: {"strict":true},
      name: "about-index-delivery"
    }, {
      path: "ecology/",
      component: _8307605c,
      pathToRegexpOptions: {"strict":true},
      name: "about-index-ecology"
    }, {
      path: "production/",
      component: _dd7db246,
      pathToRegexpOptions: {"strict":true},
      name: "about-index-production"
    }, {
      path: "stores/",
      component: _ecf36d4e,
      pathToRegexpOptions: {"strict":true},
      name: "about-index-stores"
    }, {
      path: "vacancy/",
      component: _1677f24a,
      pathToRegexpOptions: {"strict":true},
      name: "about-index-vacancy"
    }, {
      path: "warranty/",
      component: _f0a81ec0,
      pathToRegexpOptions: {"strict":true},
      name: "about-index-warranty"
    }, {
      path: "contacts/central/",
      component: _0b3df646,
      pathToRegexpOptions: {"strict":true},
      name: "about-index-contacts-central"
    }, {
      path: "delivery/credit/",
      component: _40af4d80,
      pathToRegexpOptions: {"strict":true},
      name: "about-index-delivery-credit"
    }, {
      path: "delivery/sberbank/",
      component: _7a489282,
      pathToRegexpOptions: {"strict":true},
      name: "about-index-delivery-sberbank"
    }]
  }, {
    path: "/actions/",
    component: _31ffb54b,
    pathToRegexpOptions: {"strict":true},
    name: "actions"
  }, {
    path: "/catalog/",
    component: _42b19967,
    pathToRegexpOptions: {"strict":true},
    name: "catalog"
  }, {
    path: "/grzegorz/",
    component: _3ba8fc0e,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "descriptions/",
      component: _899cb7ea,
      pathToRegexpOptions: {"strict":true},
      name: "grzegorz-index-descriptions"
    }, {
      path: "pending-answers/",
      component: _aa591b7e,
      pathToRegexpOptions: {"strict":true},
      name: "grzegorz-index-pending-answers"
    }, {
      path: "product-images/",
      component: _d653efac,
      pathToRegexpOptions: {"strict":true},
      name: "grzegorz-index-product-images"
    }, {
      path: "refs/",
      component: _33c34414,
      pathToRegexpOptions: {"strict":true},
      name: "grzegorz-index-refs"
    }, {
      path: "reviews/",
      component: _4c3e87c6,
      pathToRegexpOptions: {"strict":true},
      name: "grzegorz-index-reviews"
    }, {
      path: "top-catalog-banners/",
      component: _df2543c6,
      pathToRegexpOptions: {"strict":true},
      name: "grzegorz-index-top-catalog-banners"
    }, {
      path: "yandex-payments/",
      component: _3a88e10b,
      pathToRegexpOptions: {"strict":true},
      name: "grzegorz-index-yandex-payments"
    }, {
      path: "",
      component: _785fc011,
      pathToRegexpOptions: {"strict":true},
      name: "grzegorz-index"
    }, {
      path: "refs/:id?/",
      component: _f4898908,
      pathToRegexpOptions: {"strict":true},
      name: "grzegorz-index-refs-id"
    }, {
      path: "reviews/:id?/",
      component: _988dbc76,
      pathToRegexpOptions: {"strict":true},
      name: "grzegorz-index-reviews-id"
    }]
  }, {
    path: "/login/",
    component: _6bb80577,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/news/",
    component: _76a6959b,
    pathToRegexpOptions: {"strict":true},
    name: "news"
  }, {
    path: "/personal/",
    component: _62b80e48,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "external-auth/",
      component: _4ce48ba6,
      pathToRegexpOptions: {"strict":true},
      name: "personal-index-external-auth"
    }, {
      path: "favorites/",
      component: _17e6e7d3,
      pathToRegexpOptions: {"strict":true},
      name: "personal-index-favorites"
    }, {
      path: "orders/",
      component: _8e82d782,
      pathToRegexpOptions: {"strict":true},
      name: "personal-index-orders"
    }, {
      path: "",
      component: _0482becb,
      pathToRegexpOptions: {"strict":true},
      name: "personal-index"
    }]
  }, {
    path: "/register/",
    component: _72b136eb,
    pathToRegexpOptions: {"strict":true},
    name: "register"
  }, {
    path: "/sales/",
    component: _0e52214c,
    pathToRegexpOptions: {"strict":true},
    name: "sales"
  }, {
    path: "/search/",
    component: _1cd782f0,
    pathToRegexpOptions: {"strict":true},
    name: "search"
  }, {
    path: "/3d/online/",
    component: _c87366c8,
    pathToRegexpOptions: {"strict":true},
    name: "3d-online"
  }, {
    path: "/about/company/",
    component: _2001e489,
    pathToRegexpOptions: {"strict":true},
    name: "about-company"
  }, {
    path: "/about/franchising/",
    component: _3c78b734,
    pathToRegexpOptions: {"strict":true},
    name: "about-franchising"
  }, {
    path: "/personal/cart/",
    component: _7373c91d,
    pathToRegexpOptions: {"strict":true},
    name: "personal-cart"
  }, {
    path: "/personal/password-reset/",
    component: _2535a24c,
    pathToRegexpOptions: {"strict":true},
    name: "personal-password-reset"
  }, {
    path: "/promo/credit/",
    component: _41ceda41,
    pathToRegexpOptions: {"strict":true},
    name: "promo-credit"
  }, {
    path: "/promo/halva/",
    component: _69799d0c,
    pathToRegexpOptions: {"strict":true},
    name: "promo-halva"
  }, {
    path: "/about/company/detail/",
    component: _788dbc2a,
    pathToRegexpOptions: {"strict":true},
    name: "about-company-detail"
  }, {
    path: "/about/franchising/presentations/",
    component: _82ef2ea6,
    pathToRegexpOptions: {"strict":true},
    name: "about-franchising-presentations"
  }, {
    path: "/personal/payment/sberbank/",
    component: _74b8ce4a,
    pathToRegexpOptions: {"strict":true},
    name: "personal-payment-sberbank"
  }, {
    path: "/",
    component: _6e89ba46,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/lt/lp/:code/",
    component: _577c1436,
    pathToRegexpOptions: {"strict":true},
    name: "lt-lp-code"
  }, {
    path: "/personal/cart/:id/",
    component: _0ad90d88,
    pathToRegexpOptions: {"strict":true},
    name: "personal-cart-id"
  }, {
    path: "/personal/password-reset/:code/",
    component: _00023757,
    pathToRegexpOptions: {"strict":true},
    name: "personal-password-reset-code"
  }, {
    path: "/actions/:path/",
    component: _6ca1b540,
    pathToRegexpOptions: {"strict":true},
    name: "actions-path"
  }, {
    path: "/catalog/:path/",
    component: _4a87145c,
    pathToRegexpOptions: {"strict":true},
    name: "catalog-path"
  }, {
    path: "/news/:code/",
    component: _71b1c958,
    pathToRegexpOptions: {"strict":true},
    name: "news-code"
  }, {
    path: "/oauth/:provider?/",
    component: _287b4d23,
    pathToRegexpOptions: {"strict":true},
    name: "oauth-provider"
  }, {
    path: "/promo/:code/",
    component: _cdc69c4c,
    pathToRegexpOptions: {"strict":true},
    name: "promo-code"
  }, {
    path: "/catalog/:path/:code/",
    component: _104e7359,
    pathToRegexpOptions: {"strict":true},
    name: "catalog-path-code"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
